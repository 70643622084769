<template>
    <v-main style="margin: 10px;">
        
        <div v-if="showEditor" :style="{ maxHeight: editorHeight, overflowY: 'auto' }">
            <div style="{
                position: absolute;
                z-index: 9;
                padding: 5px 10px;
            }">
        <v-card color="#00b0b9" v-if="isImpersonate == true" class="mt-2 px-2 py-1 d-flex align-center">
                Impersonated login with &nbsp;
                <b>{{ $route.query.email  }}</b> &nbsp;|&nbsp;
                {{  ($route.query.agency ? 'Account : ' + $route.query.agency : '') }} &nbsp;|&nbsp;
                {{ ($route.query.agencyGroup ? 'Parent account: ' + $route.query.agencyGroup : '') }} &nbsp;|&nbsp;
                {{ ($route.query.date ? 'Log in date: ' + $route.query.date : '')  }}
            </v-card>
        </div>
            <v-container fluid style="max-width: 1296px;">
                <draggable class="row" v-model="previewList" @start="drag = true" @end="drag = false; onChangeDrag()">
                    <duo-tile @onClickEdit="showEditDialog(item)"
                        @onClickHide="showDialogConfirmation('unpublish', item.id)" :is-preview=" isImpersonate == true ? 1 : 2"
                        v-for="(item, idx) in previewList" :map="item.map" :img="item.image" :img-only="item.imageOnly"
                        :title="item.title" :content="item.content" :size="item.size" :btn-hero-banner="item.map.buttonHeroBanner" :key="idx"
                        :btn-label="item.buttonLabel" />
                </draggable>
            </v-container>
        </div>
        <v-card color="transparent" class="mt-2 py-1 d-flex align-center"><v-btn v-if="isImpersonate == false" class="mx-2" @click="dialogLibrary = true"
                color="duo-primary">Tile Library</v-btn>
            <v-btn v-if="isImpersonate == false" @click="dialogImpersonate = true" color="duo-primary">Impersonate</v-btn>
            <v-btn v-if="isImpersonate == true" @click="$router.push('/administration/creator/tile/dashboard')" color="duo-secondary">Exit Impersonate</v-btn>
            <v-btn v-if="isImpersonate == false" class="ml-2" @click="$router.push( '/administration/creator/tile/dashboard?date=' + new Date().toISOString().slice(0, 10))" color="duo-primary">Visible to All</v-btn>
            <v-spacer />
            <v-btn @click="resetLayout" color="duo-secondary">Reset Layout</v-btn>
            <v-btn class="mx-2" :disabled="previewListOld == previewListCurrent" @click="arrangeTiles"
                color="duo-primary">Publish
                Layout</v-btn></v-card>
        
        <!-- Update Dialog -->
        <v-container v-if="dialogUpdate" class="ma-0 pa-0 fullscreen-container" fluid>
            <v-card>
                <!-- Close Dialog -->
                <v-card-title>
                    <v-row>
                        <v-col></v-col>
                        <v-col cols="auto">
                            <v-icon @click="closeDialog">mdi-close</v-icon>
                        </v-col>
                    </v-row>
                </v-card-title>

                <div style="max-width: 1296px; margin: 20px auto 20px auto;">
                    <!-- Form Section -->
                    <v-row class="d-flex">
                        <v-form ref="form" class="cols col-12">
                            <v-card>
                                <v-tabs left>
                                    <!-- Tab Headers -->
                                    <v-tab>General</v-tab>
                                    <v-tab>Link Type</v-tab>
                                    <v-tab v-if="entity.imageOnly && entity.size == 3" >Button</v-tab>

                                    <!-- Tab Contents -->
                                    <v-tab-item>
                                        <v-container fluid class="py-8 px-12">
                                            <v-row class="d-flex">
                                                <v-col cols="12" style="margin-top: 10px">
                                                    <v-row>
                                                        <v-col cols="6">
                                                            <v-text-field v-model="entity.reportTitle" label="Report Title"
                                                                :rules="[(v) => !!v || 'Enter Report Title']" />
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-select v-model="entity.size" :items="[1, 2, 3]" label="Size"
                                                                class="duo-description rounded-0" append-icon="mdi-chevron-down">
                                                            </v-select>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-menu ref="startDateEdit" v-model="isShowEditStartDate"
                                                                :close-on-content-click="false" :return-value.sync="entity.transitMap.startDate
                                                                    " transition="scale-transition" offset-y max-width="290px">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field v-model="entity.transitMap.startDate
                                                                        " label="Start Date" :rules="[
                                                                        (v) =>
                                                                            !!v ||
                                                                            'Enter Start Date',
                                                                    ]" prepend-icon="mdi-calendar-clock" v-bind="attrs" v-on="on" dense autocomplete="off" @click="resetTime" />
                                                                                                                </template>
                                                                                                                <v-date-picker :min="new Date()
                                                                                                                    .toISOString()
                                                                                                                    .substr(0, 10)
                                                                                                                    " v-model="entity.transitMap.startDate
                                                                        " @input="
                                                                        entity.transitMap.startDate = changeDatetime(
                                                                            entity.transitMap.startDate
                                                                        )
                                                                        " color="duo-primary" no-title>
                                                                    <div class="d-flex flex-column" style="width: 100%">
                                                                        <div class="d-flex flex-row align-space-arround">
                                                                            <span>Time</span>
                                                                            <v-select v-model="hour" :items="Array(24)
                                                                                .fill()
                                                                                .map((x, i) =>
                                                                                    (
                                                                                        '' + i
                                                                                    ).padStart(
                                                                                        2,
                                                                                        '0'
                                                                                    )
                                                                                )
                                                                                " class="mx-2" style="width: 10px" dense
                                                                                append-icon="false" @input="
                                                                                    entity.transitMap.startDate = changeDatetime(
                                                                                        entity
                                                                                            .transitMap
                                                                                            .startDate
                                                                                    )
                                                                                    ">
                                                                                <template v-slot:selection="{
                                                                                    item,
                                                                                }">
                                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                                        style="width: 100%">{{ item }}</span>
                                                                                </template> </v-select>:
                                                                            <v-select v-model="minute" :items="Array(60)
                                                                                .fill()
                                                                                .map((x, i) =>
                                                                                    (
                                                                                        '' + i
                                                                                    ).padStart(
                                                                                        2,
                                                                                        '0'
                                                                                    )
                                                                                )
                                                                                " class="mx-2" style="width: 10px" dense
                                                                                append-icon="false" @input="
                                                                                    entity.transitMap.startDate = changeDatetime(
                                                                                        entity
                                                                                            .transitMap
                                                                                            .startDate
                                                                                    )
                                                                                    ">
                                                                                <template v-slot:selection="{
                                                                                    item,
                                                                                }">
                                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                                        style="width: 100%">{{ item }}</span>
                                                                                </template> </v-select>:
                                                                            <v-select v-model="second" :items="Array(60)
                                                                                .fill()
                                                                                .map((x, i) =>
                                                                                    (
                                                                                        '' + i
                                                                                    ).padStart(
                                                                                        2,
                                                                                        '0'
                                                                                    )
                                                                                )
                                                                                " class="mx-2" style="width: 10px" dense
                                                                                append-icon="false" @input="
                                                                                    entity.transitMap.startDate = changeDatetime(
                                                                                        entity
                                                                                            .transitMap
                                                                                            .startDate
                                                                                    )
                                                                                    ">
                                                                                <template v-slot:selection="{
                                                                                    item,
                                                                                }">
                                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                                        style="width: 100%">{{ item }}</span>
                                                                                </template>
                                                                            </v-select>
                                                                        </div>
                                                                        <v-divider></v-divider>
                                                                        <div class="d-flex justify-end pa-1">
                                                                            <v-btn small width="20px" class="mr-2" color="duo-secondary"
                                                                                @click="
                                                                                    isShowEditStartDate = false
                                                                                    ">Cancel</v-btn>
                                                                            <v-btn small width="20px" color="duo-primary" @click="
                                                                                $refs.startDateEdit.save(
                                                                                    (entity.transitMap.startDate = changeDatetime(
                                                                                        entity
                                                                                            .transitMap
                                                                                            .startDate
                                                                                    ))
                                                                                )
                                                                                ">OK</v-btn>
                                                                        </div>
                                                                    </div>
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-menu ref="endDateEdit" v-model="isShowEditEndDate"
                                                                :close-on-content-click="false" :return-value.sync="entity.transitMap.endDate
                                                                    " transition="scale-transition" offset-y max-width="290px">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field v-model="entity.transitMap.endDate
                                                                        " label="End Date" prepend-icon="mdi-calendar-clock"
                                                                        v-bind="attrs" v-on="on" dense autocomplete="off"
                                                                        @click="resetTime" />
                                                                </template>
                                                                <v-date-picker :min="entity.transitMap.startDate && new Date().toISOString().substr(0, 10) < entity.transitMap.startDate ? entity.transitMap.startDate :
                                                                    new Date().toISOString().substr(0, 10)
                                                                    " v-model="entity.transitMap.endDate" @input="
                                                                        entity.transitMap.endDate = changeDatetime(
                                                                            entity.transitMap.endDate
                                                                        )
                                                                        " color="duo-primary" no-title>
                                                                    <div class="d-flex flex-column" style="width: 100%">
                                                                        <div class="d-flex flex-row align-space-arround">
                                                                            <span>Time</span>
                                                                            <v-select v-model="hour" :items="Array(24)
                                                                                .fill()
                                                                                .map((x, i) =>
                                                                                    (
                                                                                        '' + i
                                                                                    ).padStart(
                                                                                        2,
                                                                                        '0'
                                                                                    )
                                                                                )
                                                                                " class="mx-2" style="width: 10px" dense
                                                                                append-icon="false" @input="
                                                                                    entity.transitMap.endDate = changeDatetime(
                                                                                        entity
                                                                                            .transitMap
                                                                                            .endDate
                                                                                    )
                                                                                    ">
                                                                                <template v-slot:selection="{
                                                                                    item,
                                                                                }">
                                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                                        style="width: 100%">{{ item }}</span>
                                                                                </template> </v-select>:
                                                                            <v-select v-model="minute" :items="Array(60)
                                                                                .fill()
                                                                                .map((x, i) =>
                                                                                    (
                                                                                        '' + i
                                                                                    ).padStart(
                                                                                        2,
                                                                                        '0'
                                                                                    )
                                                                                )
                                                                                " class="mx-2" style="width: 10px" dense
                                                                                append-icon="false" @input="
                                                                                    entity.transitMap.endDate = changeDatetime(
                                                                                        entity
                                                                                            .transitMap
                                                                                            .endDate
                                                                                    )
                                                                                    ">
                                                                                <template v-slot:selection="{
                                                                                    item,
                                                                                }">
                                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                                        style="width: 100%">{{ item }}</span>
                                                                                </template> </v-select>:
                                                                            <v-select v-model="second" :items="Array(60)
                                                                                .fill()
                                                                                .map((x, i) =>
                                                                                    (
                                                                                        '' + i
                                                                                    ).padStart(
                                                                                        2,
                                                                                        '0'
                                                                                    )
                                                                                )
                                                                                " class="mx-2" style="width: 10px" dense
                                                                                append-icon="false" @input="
                                                                                    entity.transitMap.endDate = changeDatetime(
                                                                                        entity
                                                                                            .transitMap
                                                                                            .endDate
                                                                                    )
                                                                                    ">
                                                                                <template v-slot:selection="{
                                                                                    item,
                                                                                }">
                                                                                    <span v-if="item" class="d-flex justify-center pl-6"
                                                                                        style="width: 100%">{{ item }}</span>
                                                                                </template>
                                                                            </v-select>
                                                                        </div>
                                                                        <v-divider></v-divider>
                                                                        <div class="d-flex justify-end pa-1">
                                                                            <v-btn small width="20px" class="mr-2" color="duo-secondary"
                                                                                @click="
                                                                                    isShowEditEndDate = false
                                                                                    ">Cancel</v-btn>
                                                                            <v-btn small width="20px" color="duo-primary" @click="
                                                                                $refs.endDateEdit.save(
                                                                                    (entity.transitMap.endDate = changeDatetime(
                                                                                        entity
                                                                                            .transitMap
                                                                                            .endDate
                                                                                    ))
                                                                                )
                                                                                ">OK</v-btn>
                                                                        </div>
                                                                    </div>
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-checkbox id="allFilter" v-model="entity.map.allFilter" class="mt-0 pt-0">
                                                                <template v-slot:label>
                                                                    <span @change="onVisibleChange()"
                                                                        style="font-size: 14px; color: #2d2926 !important">Visible to
                                                                        all</span>
                                                                </template>
                                                            </v-checkbox>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-checkbox :disabled="entity.map.allFilter == true" id="domainFilter"
                                                                v-model="entity.map.domainFilter" class="mt-0">
                                                                <template v-slot:label>
                                                                    <span style="font-size: 14px; color: #2d2926 !important"><b>Only</b>
                                                                        visible
                                                                        to
                                                                        users with domain ending</span>
                                                                </template>
                                                            </v-checkbox>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field :disabled="entity.map.domainFilter == false" class="mt-0 pt-0"
                                                                v-model="entity.map.domainList" label="Domain List" hide-details />
                                                            <sub>Tip: use comma to separate between lists, example: @airnz.co.nz, @mytravel.com</sub>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-checkbox :disabled="entity.map.allFilter == true" id="agencyGroupFilter"
                                                                v-model="entity.map.agencyGroupFilter" class="mt-0 pt-0"
                                                                :hide-details="true">
                                                                <template v-slot:label>
                                                                    <span style="font-size: 14px; color: #2d2926 !important"><b>Only</b>
                                                                        visible
                                                                        to
                                                                        account / parent account that starts with</span>
                                                                </template>
                                                            </v-checkbox>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field :disabled="entity.map.agencyGroupFilter == false"
                                                                class="mt-0 pt-0" v-model="entity.map.agencyGroupList"
                                                                label="Account / Parent Account List" hide-details />
                                                            <sub>Tip: use comma to separate between lists, example: MTA, Travel Managers, Express</sub>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-row class="d-flex align-center" no-gutters>
                                                                <v-col cols="auto"> <v-btn color="duoQuaternary" class="mb-0 white--text"
                                                                        @click="openImageLibraryDialog">Images Library</v-btn></v-col>
                                                                <v-col> 
                                                                    <v-text-field v-if="attachmentLabel.length > 0" v-model="attachmentLabel" placeholder="Upload File"
                                                                        prepend-icon="mdi-file" @click="openFileDialog()"
                                                                        readonly></v-text-field>
                                                                    <v-text-field v-else v-model="entity.image" placeholder="Upload File"
                                                                        prepend-icon="mdi-file" @click="openFileDialog()"
                                                                        readonly></v-text-field>
                                                                    <input type="file" ref="attachment"
                                                                        accept="image/png, image/jpeg, image/jpg" style="display: none;"
                                                                        @change="showImage" required />
                                                                </v-col>
                                                                <v-col cols="auto"> <v-icon color="duoQuaternary" class="mb-0 white--text"
                                                                        @click="delete entity.image; attachment = null; attachmentLabel = ''; refresh();">mdi-trash-can</v-icon></v-col>
                                                            </v-row>
                                                            <sub v-if="entity.imageOnly == true">Recommended image size desktop: {{ entity.size == 1 ? '432 x 376 px' : entity.size == 2 ? '830 x 376 px' : '1272 x 376 px' }}</sub>
                                                            <sub v-else>Recommended image size desktop: <b>{{ entity.size == 1 ? '432 x 196 px' : entity.size == 2 ? '420 x 376 px' : '648 x 376 px' }}</b></sub>
                                                        </v-col>
                                                        <v-col cols="12" v-if="entity.size == 3">
                                                            <v-row class="d-flex align-center" no-gutters>
                                                                <v-col cols="auto"> <v-btn color="duoQuaternary" class="mb-0 white--text"
                                                                        @click="openImageLibraryDialogMobile">Images Library</v-btn></v-col>
                                                                <v-col> 
                                                                    <v-text-field v-if="attachmentLabelMobile.length > 0" v-model="attachmentLabelMobile" placeholder="Upload File Mobile"
                                                                        prepend-icon="mdi-file" @click="openFileDialogMobile()"
                                                                        readonly></v-text-field>
                                                                    <v-text-field v-else v-model="entity.imageMobile" placeholder="Upload File"
                                                                        prepend-icon="mdi-file" @click="openFileDialogMobile()"
                                                                        readonly></v-text-field>
                                                                    <input type="file" ref="attachmentMobile"
                                                                        accept="image/png, image/jpeg, image/jpg" style="display: none;"
                                                                        @change="showImageMobile" required />
                                                                </v-col>
                                                                <v-col cols="auto"> <v-icon color="duoQuaternary" class="mb-0 white--text"
                                                                        @click="delete entity.imageMobile; attachmentMobile = null; attachmentLabelMobile = ''; refresh();">mdi-trash-can</v-icon></v-col>
                                                            </v-row>
                                                            <sub>Recommended image size mobile: 342 x 296 px</sub>
                                                        </v-col>
                                                        <v-col cols="12">
                                                            <v-checkbox id="imageOnly" v-model="entity.imageOnly" @change="refresh" class="mt-0 pt-0"
                                                                :hide-details="true">
                                                                <template v-slot:label>
                                                                    <span style="font-size: 14px; color: #2d2926 !important">Display Image
                                                                        Only</span>
                                                                </template>
                                                            </v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-tab-item>
                                    <v-tab-item>
                                        <v-container fluid class="py-8 px-12">
                                            <v-row class="d-flex">
                                                <v-col :cols="entity.linkType != 'Popup' ? 6:12">
                                                    <v-select @change="delete entity.link; resetPopupIfBlank();" v-model="entity.linkType" :items="entity.size == 3 ? ['Url', 'Creator_page', 'Video', 'Popup'] : ['Url', 'Creator_page', 'Video']"
                                                        label="Type" class="duo-description rounded-0"
                                                        append-icon="mdi-chevron-down" :rules="[(v) => !!v || 'Enter Link Type']">
                                                        <template v-slot:selection="{ item }">
                                                            <span>{{ item.replaceAll('_',' ').replace('Url', 'URL') }}</span>
                                                        </template>
                                                        <template v-slot:item="{ item }">
                                                            <span>{{ item.replaceAll('_',' ').replace('Url', 'URL') }}</span>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-select v-if="entity.linkType == 'Creator_page'" v-model="entity.link"
                                                        :rules="[(v) => !!v || 'Enter Link']" :items="landingPageList"
                                                        item-value="id" item-text="title" label="Link"
                                                        :class="{ 'line-blue': entity.link != null }"
                                                        class="duo-description rounded-0" append-icon="mdi-chevron-down">
                                                        <template v-slot:item="{ item }">
                                                            <span class="duo-description">{{ item.title }}</span>
                                                        </template>
                                                    </v-select>
                                                    <v-text-field v-else-if="entity.linkType !== 'Popup'" @blur="convertVideo()" v-model="entity.link" label="Link"
                                                        :rules="[(v) => !!v || 'Enter Link', (v) => $root.isURL(v) || 'Invalid link, link should start with http:// or https://']" />
                                                </v-col>
                                            </v-row>
                                            <!-- Preview Link Type -->
                                            <v-row class="d-flex">
                                                <v-col cols="12">
                                                    <v-row class="d-flex justify-end">
                                                        <v-btn @click="previewLink(entity)" class="white--text" color="duo-primary" style="font-size: 16px; height: 45px; width: 220px; text-transform: none !important;">Preview {{ entity.linkType ? entity.linkType.toString().replaceAll('_',' ').replace('Url', 'URL') : 'Link Type' }}</v-btn>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <!-- Setup Popup -->
                                            <v-row v-if="entity.linkType == 'Popup' && entity.size == 3" class="d-flex">
                                                <v-col cols="12" class="pt-6">
                                                    <v-card rounded="0">
                                                        <v-tabs left>
                                                            <v-tab>Heading/Body</v-tab>
                                                            <v-tab>Question/Answer</v-tab>
                                                            <v-tab>Circles/Images</v-tab>
                                                            <v-tab>Term and Condition</v-tab>
                                                            <v-tab>Button</v-tab>

                                                            <!-- Heading and Body -->
                                                            <v-tab-item>
                                                                <v-container fluid class="py-8 px-10">
                                                                    <v-row class="d-flex">
                                                                        <v-col cols="12">
                                                                            <div>
                                                                                <p style="height: 10px;"><b>Popup Background Color</b></p>
                                                                                <v-radio-group v-model="popup.backgroundColor" row>
                                                                                    <v-radio color="grey" label="White" value="white"></v-radio>
                                                                                    <v-radio color="black" label="Black" value="black"></v-radio>
                                                                                    <v-radio color="#440099" label="Violet" value="#1C1044"></v-radio>
                                                                                </v-radio-group>
                                                                            </div>
                                                                            <div>
                                                                                <p style="height: 10px;"><b>Heading Text</b></p>
                                                                                <editor key="popupHeadingText" v-model="popup.headingText" :api-key="$tinyMceKey" :init="initEditorTile" />
                                                                            </div>
                                                                            <div class="pt-6">
                                                                                <p style="height: 10px;"><b>Body Text</b></p>
                                                                                <editor key="popupBodyText" v-model="popup.bodyText" :api-key="$tinyMceKey" :init="initEditorTile" />
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-tab-item>
                                                            
                                                            <!-- Question -->
                                                            <v-tab-item>
                                                                <v-container fluid class="py-8 px-10">
                                                                    <v-row class="d-flex">
                                                                        <v-col cols="12">
                                                                            <div>
                                                                                <p style="height: 10px;"><b>Question Heading Text</b></p>
                                                                                <editor key="popupQuestionHeadingText" v-model="popup.questionHeadingText" :api-key="$tinyMceKey" :init="initEditorTile" />
                                                                            </div>
                                                                            <div class="pt-6">
                                                                                <p style="height: 10px;"><b>Answer Limit</b></p>
                                                                                <v-text-field 
                                                                                    v-model="popup.answerMaximumWord"
                                                                                    :rules="[(v) => !!v || 'This field is required']" 
                                                                                    label="Maximum Words for Answer"
                                                                                    type="number"
                                                                                />
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-tab-item>

                                                            <!-- Circles / Images -->
                                                            <v-tab-item>
                                                                <v-container fluid class="py-8 px-10">
                                                                    <v-row class="d-flex">
                                                                        <v-col cols="12">
                                                                            <div>
                                                                                <p style="height: 10px;"><b>Circles</b></p>
                                                                                <p style="height: 20px;">Up to 6 circle images. Each with a maximum width of 130px for 6 in total.</p>
                                                                                <v-select v-model="popup.totalCircles"
                                                                                    :rules="[(v) => (!!v || v == 0) || 'Enter How Many Circles']" :items="[0, 1, 2, 3, 4, 5, 6]"
                                                                                    label="How Many Circles?"
                                                                                    class="duo-description rounded-0" append-icon="mdi-chevron-down"
                                                                                    style="height: 50px;">
                                                                                    <template v-slot:item="{ item }">
                                                                                        <span class="duo-description">{{ item }}</span>
                                                                                    </template>
                                                                                </v-select>
                                                                                <div v-if="popup.totalCircles && popup.totalCircles > 0">
                                                                                    <div v-if="popup.totalCircles >= 1" class="pt-2">
                                                                                        <p style="height: 10px;"><b>Circles One</b></p>
                                                                                        <editor key="popupCircles1" v-model="popup.circle1" :api-key="$tinyMceKey" :init="initEditorImageTextTile" />
                                                                                    </div>
                                                                                    <div v-if="popup.totalCircles >= 2" class="pt-2">
                                                                                        <p style="height: 10px;"><b>Circles Two</b></p>
                                                                                        <editor key="popupCircles2" v-model="popup.circle2" :api-key="$tinyMceKey" :init="initEditorImageTextTile" />
                                                                                    </div>
                                                                                    <div v-if="popup.totalCircles >= 3" class="pt-2">
                                                                                        <p style="height: 10px;"><b>Circles Three</b></p>
                                                                                        <editor key="popupCircles3" v-model="popup.circle3" :api-key="$tinyMceKey" :init="initEditorImageTextTile" />
                                                                                    </div>
                                                                                    <div v-if="popup.totalCircles >= 4" class="pt-2">
                                                                                        <p style="height: 10px;"><b>Circles Four</b></p>
                                                                                        <editor key="popupCircles4" v-model="popup.circle4" :api-key="$tinyMceKey" :init="initEditorImageTextTile" />
                                                                                    </div>
                                                                                    <div v-if="popup.totalCircles >= 5" class="pt-2">
                                                                                        <p style="height: 10px;"><b>Circles Five</b></p>
                                                                                        <editor key="popupCircles5" v-model="popup.circle5" :api-key="$tinyMceKey" :init="initEditorImageTextTile" />
                                                                                    </div>
                                                                                    <div v-if="popup.totalCircles >= 6" class="pt-2">
                                                                                        <p style="height: 10px;"><b>Circles Six</b></p>
                                                                                        <editor key="popupCircles6" v-model="popup.circle6" :api-key="$tinyMceKey" :init="initEditorImageTextTile" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-tab-item>

                                                            <!-- Term and Condition -->
                                                            <v-tab-item>
                                                                <v-container fluid class="py-8 px-10">
                                                                    <v-row class="d-flex">
                                                                        <v-col cols="12">
                                                                            <div>
                                                                                <p style="height: 10px;"><b>Term and Condition</b></p>
                                                                                <v-row class="d-flex">
                                                                                    <v-col cols="3">
                                                                                        <v-select key="popupTermCondition" v-model="popup.isHaveTermCondition" :items="[true, false]"
                                                                                            label="Do you have term and condition?" class="duo-description rounded-0"
                                                                                            append-icon="mdi-chevron-down" :rules="[(v) => v !== null && v !== undefined || 'Enter Answer']">
                                                                                            <template v-slot:selection="{ item }">
                                                                                                <span>{{ item ? 'Yes':'No' }}</span>
                                                                                            </template>
                                                                                            <template v-slot:item="{ item }">
                                                                                                <span>{{ item ? 'Yes':'No' }}</span>
                                                                                            </template>
                                                                                        </v-select>
                                                                                    </v-col>
                                                                                    <v-col cols="9">
                                                                                        <v-combobox 
                                                                                            key="popupTermCondition" 
                                                                                            v-if="popup.isHaveTermCondition"
                                                                                            v-model="popup.termConditionLink"
                                                                                            :rules="[
                                                                                                (v) => !!v || 'Enter Term and Condition',
                                                                                                (v) => hasValidDomainOrIsItem(v) || 'Invalid Term and Condition, should start with http:// or https://'
                                                                                            ]" 
                                                                                            :items="termConditionPageList"
                                                                                            item-value="id"
                                                                                            item-text="title"
                                                                                            label="Choose from creator page or input external URL"
                                                                                            :class="{ 'line-blue': popup.termConditionLink != null }"
                                                                                            class="duo-description rounded-0"
                                                                                        >
                                                                                            <template v-slot:append>
                                                                                                <v-icon class="mr-2">mdi-chevron-down</v-icon>
                                                                                                <v-icon @click="popup.termConditionLink = null;">mdi-close</v-icon>
                                                                                            </template>
                                                                                            <template v-slot:item="{ item }">
                                                                                                <span class="duo-description">{{ item.title }}</span>
                                                                                            </template>
                                                                                        </v-combobox>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-tab-item>

                                                            <!-- Button -->
                                                            <v-tab-item>
                                                                <v-container fluid class="py-8 px-10">
                                                                    <v-row class="d-flex">
                                                                        <v-col cols="12">
                                                                            <div>
                                                                                <p style="height: 10px;"><b>Button Background Color</b></p>
                                                                                <v-radio-group v-model="popup.buttonColor" row>
                                                                                    <v-radio color="grey" label="White" value="white"></v-radio>
                                                                                    <v-radio color="black" label="Black" value="black"></v-radio>
                                                                                    <v-radio color="#440099" label="Violet" value="#440099"></v-radio>
                                                                                </v-radio-group>
                                                                            </div>
                                                                            <div>
                                                                                <p style="height: 10px;"><b>Button Text</b></p>
                                                                                <editor key="popupButtonText" v-model="popup.buttonText" :api-key="$tinyMceKey" :init="initEditorTile" />
                                                                            </div>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </v-tab-item>
                                                        </v-tabs>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-tab-item>
                                    <!-- CTA Button for Hero Banner 3 tiles width dashboard only -->
                                    <v-tab-item v-if="entity.imageOnly && entity.size == 3">
                                        <v-container fluid class="py-8 px-12">
                                            <v-row class="d-flex">
                                                <v-col cols="12" style="margin-top: 10px">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-row>
                                                                <v-col cols="6">
                                                                    <v-select key="buttonHeroBannerType" v-model="buttonHeroBanner.type" :items="['none', 'custom']"
                                                                        label="Button" class="duo-description rounded-0"
                                                                        append-icon="mdi-chevron-down" :rules="[(v) => !!v || 'Enter Button Type']">
                                                                        <template v-slot:selection="{ item }">
                                                                            <span>{{ item.charAt(0).toUpperCase() + item.slice(1) }}</span>
                                                                        </template>
                                                                        <template v-slot:item="{ item }">
                                                                            <span>{{ item.charAt(0).toUpperCase() + item.slice(1) }}</span>
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <!-- Button -->
                                                                <v-col v-if="buttonHeroBanner.type && buttonHeroBanner.type == 'custom'" cols="12">
                                                                    <div>
                                                                        <p style="height: 10px;"><b>Button Text</b></p>
                                                                        <editor v-model="buttonHeroBanner.label" :api-key="$tinyMceKey" :init="initEditorTile" key="buttonHeroBannerLabel" />
                                                                    </div>
                                                                    <div class="pt-6">
                                                                        <p style="height: 10px;"><b>Button Position</b></p>
                                                                        <v-radio-group v-model="buttonHeroBanner.position" row>
                                                                            <v-radio label="Left" value="left"></v-radio>
                                                                            <v-radio label="Right" value="right"></v-radio>
                                                                        </v-radio-group>
                                                                    </div>
                                                                    <div>
                                                                        <p style="height: 10px;"><b>Button Color</b></p>
                                                                        <v-radio-group v-model="buttonHeroBanner.color" row>
                                                                            <v-radio color="grey" label="White" value="white"></v-radio>
                                                                            <v-radio color="black" label="Black" value="black"></v-radio>
                                                                            <v-radio color="#440099" label="Violet" value="#440099"></v-radio>
                                                                        </v-radio-group>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-tab-item>
                                </v-tabs>
                            </v-card>
                        </v-form>
                    </v-row>

                    <!-- Preview Image Section -->
                    <v-row class="mt-6">
                        <duo-tile key="duo-tile-preview-in-popup" :img-only="entity.imageOnly" :map="entity.map" :title="entity.title"
                            :content="entity.content" :img="entity.image" :size="entity.size" @change="refresh" @onClickDialog="previewLink(entity)"
                            :btn-label="entity.buttonLabel" :btn-hero-banner="buttonHeroBanner" :is-preview="1" :type="entity.linkType" />
                        <v-col v-if="!entity.imageOnly" cols="12" :md="entity.size >= 2 ? 12 : 8">
                            <v-card class="d-flex flex-column align-content-center" min-height="155">
                                <v-container class="align-center pa-0 justify-center" style="margin-top: 30px">
                                    <v-row class="d-flex flex-wrap">
                                        <v-col cols="12">
                                            <v-tabs v-model="tab" color="white" height="50" background-color="#424242"
                                                slider-color="#90CAF9" slider-size="4" dark>
                                                <v-tab v-for="(tabItem, tabItemIndex) in tabItemList" :key="tabItemIndex"
                                                    class="px-12" style="background-color: #424242">
                                                    <div class="text-h6 text-capitalize">
                                                        {{ tabItem }}
                                                    </div>
                                                </v-tab>
                                            </v-tabs>

                                            <v-tabs-items v-model="tab">
                                                <v-tab-item class="my-3 mx-12">
                                                    <v-col cols="4"><v-select v-if="entity.size == 1" outlined dense
                                                            v-model="entity.map.headingTheme" :items="['light', 'dark']"
                                                            label="Title Color" class="duo-description rounded-0"
                                                            append-icon="mdi-chevron-down" /></v-col>
                                                    <editor v-if="loaded == true" v-model="entity.title"
                                                        :api-key="$tinyMceKey"
                                                        :init="initEditorHead" />
                                                </v-tab-item>
                                                <v-tab-item class="row my-3 mx-12">
                                                    <v-col cols="4"><v-select outlined dense
                                                            v-model="entity.map.contentTheme" :items="['light', 'dark']"
                                                            label="Tile Theme" class="duo-description rounded-0"
                                                            append-icon="mdi-chevron-down" /></v-col>
                                                    <v-col cols="4"><v-select v-if="entity.size > 1" outlined dense
                                                            v-model="entity.map.contentIcon"
                                                            :items="['none', 'duo-icon-help', 'duo-icon-messages', 'duo-icon-training', 'duo-icon-warning', 'icon-anz-airplane', 'icon-anz-calendar', 'icon-anz-logo', 'icon-anz-person']"
                                                            label="Add Content Icon" class="duo-description rounded-0"
                                                            append-icon="mdi-chevron-down" /></v-col>
                                                    <v-col cols="12">
                                                        <editor v-if="loaded == true" v-model="entity.content"
                                                            :api-key="$tinyMceKey"
                                                            :init="initEditor" />
                                                    </v-col>
                                                </v-tab-item>
                                                <v-tab-item class="my-3 mx-12">
                                                    <v-textarea solo v-model="entity.buttonLabel" :key="'text-area-button'" />
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>

                <v-row
                    class="d-flex align-center justify-end"
                    style="position: sticky; bottom: 0; left: 0; background-color: white; padding: 16px; z-index: 10; box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);"
                >
                    <v-btn @click="save" color="duo-primary">Save</v-btn>
                    <v-btn @click="showPreviewTileDialog(entity)" color="duo-secondary">Preview</v-btn>
                    <v-btn @click="closeDialog" color="duo-secondary">Cancel</v-btn>
                </v-row>
            </v-card>
        </v-container>

        <v-dialog v-model="dialogLibrary">
            <v-card>
                <v-card-title>
                    <h2>Homepage Creator</h2>
                </v-card-title>
                <v-card-text>
                    <v-data-table style="max-height: 70vh; overflow-y: auto;" :headers="headers" :items="creatorTileList"
                        :items-per-page="20">
                        <template v-slot:item.title="{ item }">
                            <div>{{ $root.ellipsify(item.reportTitle, 100) }}</div>
                        </template>
                        <template v-slot:item.content="{ item }">
                            <div v-html="$root.ellipsifyHtml(item.content, 100)"/>
                        </template>
                        <template v-slot:item.link="{ item }">
                            <div>{{ $root.ellipsify(item.link, 100) }}</div>
                        </template>
                        <template v-slot:item.image="{ item }">
                            <div>{{ $root.ellipsify(item.image, 100) }}</div>
                        </template>
                        <template v-slot:item.active="{ item }">
                            <div class="d-flex flex-row">
                                <v-btn @click="showDialogConfirmation('publish', item.id)" prepend-icon="mdi-plus"
                                    v-if="!item.sequence" small dark color="secondary" title="Add">
                                    Publish
                                </v-btn>
                                <v-icon v-else>mdi-check</v-icon>
                            </div>
                        </template>
                        <template v-slot:item.domainList="{ item }">
                            <div class="d-flex flex-row">
                                {{ (item.map.allFilter && item.map.allFilter == true) || (!item.map.domainFilter || item.map.domainFilter == false) ? '' : item.map.domainList }}
                            </div>
                        </template>
                        <template v-slot:item.agencyGroupList="{ item }">
                            <div class="d-flex flex-row">
                                {{ (item.map.allFilter && item.map.allFilter == true) || (!item.map.agencyGroupFilter || item.map.agencyGroupFilter == false) ? '' : item.map.agencyGroupList }}
                            </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <div class="d-flex flex-row">
                                <v-icon class="ml-2" @click="showEditDialog(item)">mdi-pencil</v-icon>
                                <v-icon class="ml-2"
                                    @click="showDialogConfirmation('delete', item.id)">mdi-trash-can</v-icon>
                                <!-- <v-icon class="ml-2" v-if="item.sequence"
                                    @click="showDialogConfirmation('unpublish', item.id)">mdi-close-circle-outline</v-icon> -->
                            </div>
                        </template>
                    </v-data-table>
                    <span class="mr-2" v-if="isImpersonate == true"><b>*Unpublished / filtered tiles not shown during Impersonation</b></span>
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-end">
                    <div class="d-flex px-2">
                        
                        <v-btn @click="showAddDialog" color="duo-primary" class="mx-2">New Tile</v-btn>
                        <v-btn @click="dialogLibrary = false" color="duo-secondary">Cancel</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog fullscreen persistent v-model="dialogPreviewPage.show">
            <!-- <v-card-title><v-row><v-col></v-col><v-col cols="auto"><v-icon
                                @click="closeDialog">mdi-close</v-icon></v-col></v-row></v-card-title> -->
            <v-container fluid style="background-color: rgba(255,255,255, 0.7); position: fixed;">
                <v-row>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-btn @click="closePreviewPage" color="duo-secondary" class="mr-2">Back</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <div class="pa-4 pt-12" :style="{backgroundImage: 'url(' + (dialogPreviewPage.entity.backgroundImage ? dialogPreviewPage.entity.backgroundImage : 'https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/BDM.Map.BG.jpg') + ')', backgroundSize: 'cover'}">
                <duo-landing-page :entity="dialogPreviewPage.entity" />
            </div>
        </v-dialog>
        
        <dialog-popup key="dialogPopupTileCreator" :show="dialogPopup.show" :data="popup" :reportTitle="entity.reportTitle" :isPreview="true" @closeDialogPopup="closeDialogPopup" />
        <confirmation-dialog :show="dialogConfirmation.show" @onClickConfirm="execDialog"
            @onCloseDialog="closeDialogConfirmation" :title="'Confirm ' + dialogConfirmation.action"
            :content="'Are you sure you want to <b>' + dialogConfirmation.action + '</b> selected tile'" />
        <dialog-images-picker :show="dialogImageLibrary.show" :list="dialogImageLibrary.list" @onClickImage="onSelectImage"
            @onCloseDialog="dialogImageLibrary.show = false" />
        <dialog-images-picker-mobile :show="dialogImageLibraryMobile.show" :list="dialogImageLibraryMobile.list" @onClickImage="onSelectImageMobile"
            @onCloseDialog="dialogImageLibraryMobile.show = false" />
        <dialog-impersonate-creator :show="dialogImpersonate" @onCloseDialog="dialogImpersonate = false" />
        <dialog-video :show="dialogVideo.show" :title="dialogVideo.title" :link="dialogVideo.link"
            @onCloseDialog="closeDialogVideo" />
    </v-main>
</template>


<style scoped>
.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10;
  overflow: auto;
  overflow-x: hidden;
  animation: fullscreen-container-animation 0.2s ease-out;
}

@keyframes fullscreen-container-animation {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import axios from "axios";
import editor from "@tinymce/tinymce-vue";
import duoTile from "./creator/duoTile.vue";
import confirmationDialog from "./dialog/confirmationDialog.vue";
import draggable from 'vuedraggable';
import dialogImagesPicker from "./dialog/dialogImagesPicker.vue"
import dialogImagesPickerMobile from "./dialog/dialogImagesPicker.vue"
import dialogImpersonateCreator from "./dialog/dialogImpersonateCreator.vue";
import dialogVideo from "./dialog/dialogVideo.vue";
import dialogPopup from "./dialog/dialogPopup.vue";
import duoLandingPage from "./creator/duoLandingPage.vue"

export default {
    components: {
        editor,
        duoTile,
        confirmationDialog,
        draggable,
        dialogImagesPicker,
        dialogImagesPickerMobile,
        dialogImpersonateCreator,
        dialogVideo,
        dialogPopup,
        duoLandingPage,
    },
    data() {
        return {
            isImpersonate: false,
            dialogImpersonate: false,
            loaded: false,
            dialogImageLibrary: {
                show: false,
                target: '',
                list: [],
            },
            dialogImageLibraryMobile: {
                show: false,
                target: '',
                list: [],
            },
            showEditor: false,
            editorHeight: '840px',
            landingPageList: [],
            termConditionPageList: [],
            headers: [
                { value: "action", sortable: false },
                { text: "Report Title", value: "title" },
                { text: "Content", value: "content" },
                { text: "Image", value: "image" },
                { text: "Published", value: "active" },
                { text: "Start Date", value: "transitMap.startDate" },
                { text: "End Date", value: "transitMap.endDate" },
                { text: "Domain Filter", value: "domainList" },
                { text: "Parent Account Filter", value: "agencyGroupList" },
                { text: "Link", value: "link", width: "100px" },
            ],
            creatorTileList: [],
            isShowEditStartDate: false,
            isShowEditEndDate: false,
            hour: "00",
            minute: "00",
            second: "00",
            tab: 0,
            tabItemList: ["header", "body", "button"],
            dialogLibrary: false,
            dialogUpdate: false,
            previewList: [],
            previewListOld: "",
            previewListCurrent: "",
            selectedId: null,
            selectedButtonHeroBannerType: 'none', //for error nested object in entity
            entity: {
                type: 'Dashboard',
                size: 1,
                map: {
                    allFilter: false,
                    domainFilter: false,
                    agencyGroupFilter: false,
                    popup: {},
                    buttonHeroBanner: {},
                },
                transitMap: {
                    dateStart: null,
                    dateEnd: null,
                },
            },
            buttonHeroBanner: {
                type: 'none',
                position: 'right',
                color: 'black',
                label: "Find out more",
            },
            popup: {
                backgroundColor: "#1C1044",
                textColor: "white",
                headingText: "",
                bodyText: "",
                circle1: "",
                circle2: "",
                circle3: "",
                circle4: "",
                circle5: "",
                circle6: "",
                totalCircles: 1,
                questionHeadingText: "",
                answerMaximumWord: 25,
                termConditionLink: "",
                buttonText: "Submit",
                buttonColor: "white"
            },
            attachment: null,
            attachmentLabel: "",
            attachmentMobile: null,
            attachmentLabelMobile: "",
            editor: {
                0: "",
                1: "",
                2: ""
            },
            initEditorHead: {
                height: 400,
                selector: "",
                menubar: false,
                plugins: ["code", "paste", "table", "lists", "link"],
                paste_as_text: true,
                toolbar:
                    "undo redo | bold italic underline | help",
            },
            initEditor: {
                height: 400,
                selector: "",
                menubar: false,
                plugins: ["code", "paste", "table", "lists", "link"],
                paste_as_text: true,
                fontsize_formats: "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px",
                toolbar:
                    "undo redo | formatselect fontsizeselect | bold italic underline | help",
                content_style: 'body{ font-size:17px; }',
            },
            initEditorTile: {
				height: 200,
				selector: "",
				menubar: false,
				plugins: ["code", "paste", "table", "lists", "link", "textcolor"],
				paste_as_text: true,
				fontsize_formats: "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 31px 32px 33px 34px 35px 36px 37px 38px 39px 40px",
				lineheight_formats: "0.5 0.75 1 1.1 1.2 1.3 1.4 1.5 2 2.5 3",
                toolbar:
						"code undo redo | formatselect | fontsizeselect fontselect lineheight | bold italic underline forecolor backcolor | table | \
				alignleft aligncenter alignright alignjustify | \
				bullist numlist outdent indent | link | removeformat | pastetext | help"
			},
            initEditorImageTextTile: {
				height: 320,
				menubar: false,
				plugins: ["code", "paste", "table", "lists", "link", "textcolor", "image"],
				selector: "file-picker",
                image_title: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                file_picker_callback: function (cb, value, meta) {
                    const input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');
                    input.onchange = function () {
                        const file = this.files[0];
                        const reader = new FileReader();
                        reader.onload = function () {
                            const id = 'blobid' + new Date().getTime();
                            const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                            const base64 = reader.result.split(',')[1];
                            const blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);
                            cb(blobInfo.blobUri(), { title: file.name });
                        };
                        reader.readAsDataURL(file);
                    };
                    input.click();
                },
                images_dataimg_filter: function(img) {
                    return img.hasAttribute('internal-blob');
                },
				paste_as_text: true,
				fontsize_formats: "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 31px 32px 33px 34px 35px 36px 37px 38px 39px 40px",
				toolbar:
						"code undo redo | image | formatselect | fontsizeselect fontselect lineheight | bold italic underline forecolor backcolor | table | \
				alignleft aligncenter alignright alignjustify | \
				bullist numlist outdent indent | link | removeformat | pastetext | help"
			},
            dialogConfirmation: {
                action: 'publish',
                show: false,
                id: null
            },
            dialogVideo: {
                show: false,
                title: "Preview Video",
                link: "",
            },
            dialogPopup: {
                show: false,
            },
            dialogPreviewPage: {
                show: false,
                entity: {
                    backgroundImage: "https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/BDM.Map.BG.jpg",
                    map: {
                        contentList: []
                    }
                },
            }
        }
    },
    mounted() {
        this.isImpersonate = false;
        this.editorHeight = window.innerHeight > 1439 ? '92vh' : window.innerHeight > 930 ? '88vh' : '85vh';
        this.showEditor = true;
        this.getList();
        this.getImageLib();
        this.getLandingPageList();
        this.getTermConditionPageList();
    },
    methods: {
        previewLink(entity) {
            if (entity.linkType == 'Url') {
                window.open(entity.link, "_blank");
            } else if (entity.linkType == 'Creator_page') {
                this.previewCreatorPage(entity.link);
            } else if (entity.linkType == 'Video') {
                this.dialogVideo.link = entity.link;
                this.dialogVideo.show = true;
            } else if (entity.linkType == 'Popup') {
                this.dialogPopup.show = true;
            }
        },
        previewCreatorPage(link) {
            this.dialogPreviewPage.show = true;
            axios.get("/system/creator/page?id=" + link).then(
                response => {
                    if (!response.data.map.contentList) {
                        response.data.map.contentList = [];
                    }
                    this.dialogPreviewPage.entity = response.data;
                }
            );
        },
        closePreviewPage() {
            this.dialogPreviewPage = {
                show: false,
                entity: {
                    backgroundImage: "https://airnzduo.s3-ap-southeast-2.amazonaws.com/image/background/BDM.Map.BG.jpg",
                    map: {
                        contentList: []
                    }
                },
            }
        },
        closeDialogVideo() {
            this.dialogVideo.show = false;
            this.dialogVideo.link = "";
        },
        closeDialogPopup() {
            this.dialogPopup.show = false;
        },
        getImageLib() {
            axios.get("/system/creator/tile/library/list")
                .then((response) => {
                    this.dialogImageLibrary.list = response.data;
                    this.dialogImageLibraryMobile.list = response.data;
                });
        },
        getLandingPageList() {
            axios.get("/system/creator/page/list/combo")
                .then((response) => {
                    this.landingPageList = response.data;
                });
        },
        getTermConditionPageList() {
            axios.get("/system/creator/page/list/tc")
                .then((response) => {
                    this.termConditionPageList = response.data;
                });
        },
        openImageLibraryDialog() {
            this.dialogImageLibrary.show = true;
        },
        openImageLibraryDialogMobile() {
            this.dialogImageLibraryMobile.show = true;
        },
        onSelectImage(item) {
            this.entity.image = this.$bucket + '/' + item;
            this.dialogImageLibrary.show = false;
        },
        onSelectImageMobile(item) {
            this.entity.imageMobile = this.$bucket + '/' + item;
            this.dialogImageLibraryMobile.show = false;
        },
        convertVideo() {
            if (this.entity.linkType == 'Video') {
                this.entity.link = this.$root.convertVideoUrl(this.entity.link);
            }
        },
        resetTime() {
            this.hour = "00";
            this.minute = "00";
            this.second = "00";
        },
        changeDatetime(dateTime) {
            if (dateTime === undefined || dateTime === "") {
                return new Date().toISOString().substr(0, 10) + " " + this.hour + ":" + this.minute + ":" + this.second;
            } else {
                return dateTime.substr(0, 10) + " " + this.hour + ":" + this.minute + ":" + this.second;
            }
        },
        getList() {

            let url = "/system/creator/tile/list";

            let requestObj = {type: 'Dashboard'}

            if (Object.keys(this.$route.query).length > 0) {
                this.isImpersonate = true;
                for (const key in this.$route.query) {
                    if(key.length > 1) {
                        requestObj[key] = this.$route.query[key];
                    }
                }
            }

            axios
                .post(url, requestObj)
                .then((response) => {
                    this.creatorTileList = response.data;
                    this.previewList = response.data.filter(function (el) {
                        return el.sequence;
                    }).sort((a, b) => {
                        return a.sequence - b.sequence;
                    });

                    this.previewListOld = this.previewList.map(function (item) {
                        return item['id'];
                    }).toString();
                    this.previewListCurrent = this.previewListOld;
                })
                .catch(() => { });
        },
        onChangeDrag() {
            this.previewListCurrent = this.previewList.map(function (item) {
                return item['id'];
            }).toString();
        },
        arrangeTiles() {
            let requestObj = { tileList: this.previewListCurrent };
            if (this.isImpersonate == true) {
                requestObj.type = "Dashboard";
            }
            
            axios.post("/system/creator/tile/arrange", requestObj).then((response) => {
                if (response.status == 200) {
                    this.getList();
                }
            });
        },
        resetLayout() {
            this.previewList = this.creatorTileList.filter(function (el) {
                return el.sequence;
            });
            this.previewListCurrent = this.previewListOld;
        },
        publishLayout() {
            this.previewList = this.creatorTileList.filter(function (el) {
                return el.sequence;
            })
        },
        addPreview(item) {
            this.previewList.push(item);
        },
        onVisibleChange() {
            if (this.entity.map.allFilter == true) {
                this.entity.map.domainFilter = false;
                this.entity.map.agencyGroupFilter = false;
            }
        },
        showAddDialog() {
            this.loaded = false;
            this.entity = {
                type: 'Dashboard',
                size: 1,
                map: {
                    allFilter: false,
                    domainFilter: false,
                    agencyGroupFilter: false,
                    popup: {}
                },
                transitMap: {
                    dateStart: null,
                    dateEnd: null,
                },
                link: "",
                buttonLabel: "Find out more",
            };
            this.resetPopup();
            this.dialogLibrary = false;
            this.dialogUpdate = true;
            this.$nextTick(() => {
                this.loaded = true;
            });
        },
        showEditDialog(item) {
            this.loaded = false;
            this.entity = { ...item };
            if (this.entity.linkType == 'Creator_page' && this.entity.link.length > 0) {
                this.entity.link = Number(this.entity.link);
            }
            
            try {                
                if (this.$root.isBlank(this.entity.map.popup)) {
                    this.entity.link = "";
                    this.resetPopup();
                    this.entity.map.popup = JSON.parse(JSON.stringify(this.popup));
                } else {
                    this.popup = JSON.parse(JSON.stringify(this.entity.map.popup));
                }

                if (this.$root.isBlank(this.entity.map.buttonHeroBanner)) {
                    this.entity.map.buttonHeroBanner = {
                        type: 'none',
                        position: 'right',
                        color: 'black',
                        label: "Find out more",
                    }
                } else {
                    this.buttonHeroBanner = JSON.parse(JSON.stringify(this.entity.map.buttonHeroBanner));
                }
            } catch(e){console.log(e)}

            this.dialogLibrary = false;
            this.dialogUpdate = true;
            this.$nextTick(() => {
                this.loaded = true;
            });
        },
        openFileDialog() {
            this.$refs.attachment.click();
        },
        openFileDialogMobile() {
            this.$refs.attachmentMobile.click();
        },
        refresh() {
            this.$forceUpdate();
        },
        showImage(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {

                if (files[0].size > 2097152) {
                    this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
                    return;
                }

                let imageName = files[0].name;
                if (imageName.lastIndexOf(".") <= 0) {
                    return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                    this.entity.image = fr.result;
                    this.attachment = files[0]; // this is an image file that can be sent to server...
                    this.attachmentLabel= files[0].name,
                    this.imgError = false;
                });
            } else {
                this.attachment = null;
                this.attachmentLabel = '';
            }
        },
        showImageMobile(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {

                if (files[0].size > 2097152) {
                    this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
                    return;
                }

                let imageName = files[0].name;
                if (imageName.lastIndexOf(".") <= 0) {
                    return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                    this.entity.imageMobile = fr.result;
                    this.attachmentMobile = files[0]; // this is an image file that can be sent to server...
                    this.attachmentLabelMobile= files[0].name,
                    this.imgError = false;
                });
            } else {
                this.attachmentMobile = null;
                this.attachmentLabelMobile = '';
            }
        },
        save() {
            if (this.$refs.form.validate()) {
                let url = '/system/creator/tile/add';

                if (this.entity.id) {
                    url = '/system/creator/tile/edit';
                    this.entity.sequence = this.previewList.length + 1;
                }

                if (this.$root.isBlank(this.entity.link)) {
                    this.entity.link = "";
                }

                if (this.$root.isBlank(this.entity.title)) {
                    this.entity.title = this.entity.reportTitle;
                }

                if (this.entity.size == 3) {
                    this.entity.map.buttonHeroBanner = JSON.parse(JSON.stringify(this.buttonHeroBanner));
                    this.entity.map.popup = JSON.parse(JSON.stringify(this.popup));
                }

                // clean preference
                if (this.entity.map.agencyGroupFilter == false &&
                    this.entity.map.domainFilter == false) {
                        this.entity.map.allFilter = true;
                } else if (this.entity.map.allFilter == true) {
                    this.entity.map.agencyGroupFilter = false;
                    this.entity.map.domainFilter = false;
                } else {
                    this.entity.map.agencyGroupList = this.$root.trimSpaces(this.entity.map.agencyGroupList);
                    this.entity.map.domainList = this.$root.trimSpaces(this.entity.map.domainList);
                }

                if (this.entity.size > 1) {
                    delete this.entity.map.headingTheme;
                }
                // end clean preference

                let formData = new FormData();
                formData.append("entity", JSON.stringify(this.entity));
                if (this.attachment) {
                    formData.append("attachment", this.attachment);
                }
                if (this.attachmentMobile) {
                    formData.append("attachmentMobile", this.attachmentMobile);
                }

                axios.post(url, formData).then(
                    (response) => {
                        if (response.status == 200) {
                            this.getList();
                            this.closeDialog();
                        }
                    }
                )
            }
        },
        closeDialog() {
            this.attachment = null;
            this.attachmentLabel = "";

            this.attachmentMobile = null;
            this.attachmentLabelMobile = "";
            
            this.dialogUpdate = false;
            if (this.entity.id) {
                this.getList();
            }

            this.entity = {
                type: 'Dashboard',
                size: 1,
                map: {
                    allFilter: false,
                    domainFilter: false,
                    agencyGroupFilter: false,
                    popup: {},
                    buttonHeroBanner: {},
                },
                transitMap: {
                    dateStart: null,
                    dateEnd: null,
                },
                buttonLabel: "Find out more",
            };
            this.buttonHeroBanner = {
                type: 'none',
                position: 'right',
                color: 'black',
                label: "Find out more",
            };
            this.resetPopup();
        },
        showDialogConfirmation(action, id) {
            this.dialogConfirmation.action = action;
            this.dialogConfirmation.id = id;
            this.dialogConfirmation.show = true;
        },
        closeDialogConfirmation() {
            this.dialogConfirmation.show = false;
            this.dialogConfirmation.id = null;
        },
        execDialog() {
            let url = "";

            let payload = { id: this.dialogConfirmation.id }

            if (this.dialogConfirmation.action == 'unpublish') {
                url = "/system/creator/tile/unpublish"
            } else if (this.dialogConfirmation.action == 'publish') {
                url = "/system/creator/tile/publish"
                payload.sequence = (this.previewList.length > 0 ? Math.max(...this.previewList.map(o => o.sequence)) : 0) + 1;
            } else if (this.dialogConfirmation.action == 'delete') {
                url = "/system/creator/tile/delete"
            }



            if (url.length > 0) {
                axios.post(url, payload).then(
                    () => {
                        this.getList();

                        this.closeDialogConfirmation();
                    }
                )
            }
        },
        removePreview(item) {
            var index = this.previewList.indexOf(item);
            if (index !== -1) {
                this.previewList.splice(index, 1);
            }
        },
        showPreviewTileDialog(entity) {
			this.closeDialog();
			this.$router.push({ path: `/administration/creator/tile/dashboard/${entity.id}`, query: entity })
        },
        hasValidDomainOrIsItem(value) {
            // Check if the value is from the item list
            if (this.termConditionPageList.some(item => item.id === (value?.id ?? 0) )) {
                return true;
            }
            // Ensure the value contains a valid URL if the input is not selected from the item list
            if (this.$root.isURL(value)) {
                return true;
            }
            return false;
        },
        resetPopupIfBlank() {
            if (this.$root.isBlank(this.entity.map.popup.headingText)) {
                this.resetPopup();
            }
        },
        resetPopup() {
            this.popup = {
                backgroundColor: "#1C1044",
                textColor: "white",
                headingText: "",
                bodyText: "",
                circle1: "",
                circle2: "",
                circle3: "",
                circle4: "",
                circle5: "",
                circle6: "",
                totalCircles: 1,
                questionHeadingText: "",
                answerMaximumWord: 25,
                isHaveTermCondition: true,
                termConditionLink: "",
                buttonText: "Submit",
                buttonColor: "white"
            };
        }
    }
}
</script>