<template>
    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" :md="size == 3 ? 12 : size == 2 ? 8 : 4">
        <v-row @click="trackAndVisit()" v-if="imgOnly == true" no-gutters>
            <v-col cols="12" class="d-flex align-end justify-start"
                :style="{ cursor: 'pointer', minHeight: '350px', backgroundImage: 'url(' + img + ')', backgroundSize: 'cover', position: 'relative' }">
                <v-sheet v-if="isPreview > 1" class="d-flex justify-end pt-2 pb-8 px-4" color="rgba(255, 255, 255, 0.8)" style="z-index: 1;">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-2"
                                :color="map.contentTheme != undefined && map.contentTheme == 'dark' ? 'white' : 'duoPrimary'"
                                @click="onClickEdit()" v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                        </template>
                        <span>Edit tile</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon class="ml-2"
                                :color="map.contentTheme != undefined && map.contentTheme == 'dark' ? 'white' : 'duoPrimary'"
                                @click="onClickHide()" v-bind="attrs" v-on="on">mdi-close-circle-outline</v-icon>
                        </template>
                        <span>Unpublish tile</span>
                    </v-tooltip>
                </v-sheet>
                <v-btn v-if="imgOnly && size == 3 && btnHeroBanner && btnHeroBanner.type == 'custom'" 
                            @click="isPreview > 1 ? '':trackAndVisit()" 
                            class="duo-text-button" min-width="193px" height="52px"
                            :style="{ zIndex: 0, bottom: '45px', left: (btnHeroBanner.position == 'left' ? '30px':'')}"
                            :color="btnHeroBanner.color"
                            :elevation="0" 
                            :right="btnHeroBanner.position == 'right'"
                            absolute bottom>
                    <div v-html="btnHeroBanner.label" style="height: 22px;" :style="{ color: btnHeroBanner.color == 'white' ? 'black':'white' }"></div>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-else style="height: 376px;" no-gutters>
            <v-col :order="size == 2 ? 1 : 2" cols="12" :md="size >= 2 ? 6 : 12" style="min-height: 180px;">
                <v-card height="100%"
                    :color="map.contentTheme != undefined && map.contentTheme == 'dark' ? 'duoQuaternary' : 'white'" tile
                    elevation="0">
                    <duo-tile-title v-if="size >= 2" :label="title"
                        :color="map.contentTheme != undefined && map.contentTheme == 'dark' ? '#FFFFFF' : '#2d2926'" />
                    <v-card-text>
                        <v-row>
                            <v-col v-if="size > 1 && map.contentIcon && map.contentIcon != 'none'" cols="12" class="pl-3">
                                <v-avatar size="76"
                                    :color="map.contentTheme != undefined && map.contentTheme == 'dark' ? 'white' : 'duoQuaternary'">
                                    <v-icon :class="map.contentIcon"
                                        :color="map.contentTheme != undefined && map.contentTheme == 'dark' ? 'duoQuaternary' : 'white'"
                                        size="32"></v-icon>
                                </v-avatar>
                            </v-col>
                            <v-col cols="12" class="pl-3 pt-3">
                                <div :style="{maxHeight: size == 1 ? '92px !important' : '238px !important', overflowY: 'hidden'}">
                                    <span
                                        :class="'duo-description ' + (map.contentTheme != undefined && map.contentTheme == 'dark' ? 'white--text' : '')"
                                        v-html="content"></span>
                                    </div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" :class="'d-inline-flex pa-0 ' + (size == 3 ? 'justify-end' : '')">
                                <v-btn @click="trackAndVisit()" class="duo-text-button" width="193px" height="50px"
                                    :style="{ fontSize: '17px', color: map.contentTheme != undefined && map.contentTheme == 'dark' ? '#2d2926' : 'white' }"
                                    :color="map.contentTheme != undefined && map.contentTheme == 'dark' ? 'white' : 'duoPrimary'"
                                    :elevation="0" absolute bottom dark>{{ btnLabel }}</v-btn>
                                <div v-if="isPreview > 1"
                                    :style="{ position: 'absolute', left: size != 3 ? '80%' : '0', right: size == 3 ? '80%' : '0', bottom: '30px' }">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="ml-2"
                                                :color="map.contentTheme != undefined && map.contentTheme == 'dark' ? 'white' : 'duoPrimary'"
                                                @click="onClickEdit()" v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                                        </template>
                                        <span>Edit tile</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon class="ml-2"
                                                :color="map.contentTheme != undefined && map.contentTheme == 'dark' ? 'white' : 'duoPrimary'"
                                                @click="onClickHide()" v-bind="attrs"
                                                v-on="on">mdi-close-circle-outline</v-icon>
                                        </template>
                                        <span>Unpublish tile</span>
                                    </v-tooltip>
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
            <v-col :order="size == 2 ? 2 : 1" cols="12" :md="size >= 2 ? 6 : 12"
                :style="{ minHeight: '196px', backgroundImage: 'url(' + img + ')', backgroundColor: '#2d2926', backgroundSize: 'cover', position: 'relative' }">
                <div v-if="size == 1" class="pt-3 pl-1"><duo-tile-title :label="title"
                        :color="map.headingTheme != undefined && map.headingTheme == 'dark' ? '#2d2926' : '#FFFFFF'" />
                </div>
            </v-col>
        </v-row>
    </v-col>

    <duo-tile-mobile v-else></duo-tile-mobile>
</template>

<script>
/* eslint-disable no-console */

import duoTileTitle from "./duoTileTitle.vue"
import duoTileMobile from "../mobile/duoTileMobile.vue"

export default {
    components: {
        duoTileTitle,
        duoTileMobile
    },
    props: {
        isPreview: {
            type: Number,
            default: 0,
        },
        size: {
            type: Number,
            default: 1,
        },
        imgOnly: {
            type: Boolean,
            default: false,
        },
        img: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        reportTitle: {
            type: String,
            default: "",
        },
        content: {
            type: String,
            default: "",
        },
        btnLabel: {
            type: String,
            default: "",
        },
        btnHeroBanner: {
            type: Object,
            default: () => ({
                type: 'none',
                position: 'right',
                color: 'black',
                label: "Find out more",
            })
        },
        type: {
            type: String,
            default: 'Url',
        },
        link: {
            type: String,
            default: 'https://google.com',
        },
        map: {
            default: {},
        },
        isvideo: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        trackAndVisit() {
            // for admin
            if (this.isPreview !== 0) return;

            // for client
            if (this.isvideo == false) {
                this.$root.saveActivityLog({ type: 'Visit_dashboard_tile', value: this.type === 'Popup' ? this.reportTitle : this.title.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '').trim() });
                if (this.size === 3 && this.imgOnly) {
                    this.sendBannerAnalytics(this.link);
                }
            }
            switch (this.type) {
                case 'Url':
                    if (this.link.includes(window.location.host)) {
                        window.location.href = this.link;
                    } else {
                        window.open(this.link, "_blank");
                    }
                    break;
                case 'Video':
                    this.onClickDialog();
                    break;
                case 'Popup':
                    this.onClickDialog();
                    break;
                case 'Creator_page':
                    this.$router.push("/page/" + this.link);
                    break;
            }
        },
        sendBannerAnalytics(url) {
            try {
                this.$gtm.trackEvent({
                    event: 'click_banner_home',
                    category: 'banner_home',
                    action: 'click',
                    label: url,
                });
            } catch (error) {
                // console.log(error);
            }
        },
        onClickEdit() {
            this.$emit('onClickEdit');
        },
        onClickHide() {
            this.$emit('onClickHide');
        },
        onClickDialog() {
            this.$emit('onClickDialog');
        }
    }
}
</script>