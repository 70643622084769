<template>
	<v-container fluid>
		<subHeader />

		<v-container fluid style="max-width: 1271px">
			<v-row>
				<v-col cols="12">
					<v-row>
						<h2><b>duo</b></h2><h2 style="font-weight: normal;">resources</h2>
					</v-row>
					<br /><br />
					<v-row>
						<p>Ready to upskill with Air New Zealand? <b>duo</b>resources is your one-stop destination for exclusive content and resources to help you learn with confidence.</p>
					</v-row>
					<v-row>
						<p>So, what are you waiting for? Check out the content below. </p>
					</v-row>
				</v-col>
			</v-row>

			<br /><br />

			<v-row>
				<!-- Trade Toolkit resources -->
				<v-col cols="4" class="ma-0 pa-0 d-flex align-center justify-center">
					<v-col cols="12" class="ma-0 pa-0">
						<v-card min-height="400" max-width="400" tile elevation="0" @click="trackAndVisit('/TradeToolkit', 'Trade Toolkit')">
							<v-img :src="$bucket + '/image/dashboard-pages/Resources.TradeToolKit.Tiles.jpg'" height="400">
								<v-app-bar flat color="rgba(0, 0, 0, 0)">
									<v-toolbar-title class="pl-1 pt-3" style="font-size: 28px; color: #2d2926;">
										<div class="d-flex flex-column">
											<span><b>Trade Toolkit</b></span>
											<span class="duo-underline"></span>
										</div>
									</v-toolbar-title>
								</v-app-bar>
							</v-img>
						</v-card>
					</v-col>
				</v-col>

				<!-- Meet your BDMs resources -->
				<v-col cols="4" class="ma-0 pa-0 d-flex align-center justify-center">
					<v-col cols="12" class="ma-0 pa-0">
						<v-card min-height="400" max-width="400" tile elevation="0" @click="visit('/resources/bdm')">
							<v-img :src="$bucket + '/image/dashboard-pages/Resources.BDM.Tiles.jpg'" height="400">
								<v-app-bar flat color="rgba(0, 0, 0, 0)">
									<v-toolbar-title class="pl-1 pt-3" style="font-size: 28px; color: #2d2926;">
										<div class="d-flex flex-column">
											<span><b>Meet your BDMs</b></span>
											<span class="duo-underline"></span>
										</div>
									</v-toolbar-title>
								</v-app-bar>
							</v-img>
						</v-card>
					</v-col>
				</v-col>

				<!-- Videos resources -->
				<v-col cols="4" class="ma-0 pa-0 d-flex align-center justify-center">
					<v-col cols="12" class="ma-0 pa-0">
						<v-card min-height="400" max-width="400" tile elevation="0" @click="visit('/resources/videos')">
							<v-img :src="$bucket + '/image/dashboard-pages/Resources.Videos.Tiles.jpg'" height="400">
								<v-app-bar flat color="rgba(0, 0, 0, 0)">
									<v-toolbar-title class="pl-1 pt-3" style="font-size: 28px; color: #2d2926;">
										<div class="d-flex flex-column">
											<span><b>Videos</b></span>
											<span class="duo-underline"></span>
										</div>
									</v-toolbar-title>
								</v-app-bar>
							</v-img>
						</v-card>
					</v-col>
				</v-col>
			</v-row>

			<br /><br />

			<v-row>
			</v-row>
		</v-container>
	</v-container>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
import axios from "axios";
import subHeader from "./subHeader.vue";

export default {
	components: {
		subHeader,
	},
	data() {
		return {
		};
	},
	mounted: function () {
	},
	methods: {
		visit(url){
			this.$router.push(url);
		},
		trackAndVisit(url, title){
			this.$root.saveActivityLog({type: 'Visit_dashboard_tile', value: title.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '').trim() });
			this.$router.push(url);
		},
		stopVideo() {
			// youtube
			var iframes = document.querySelectorAll("iframe");
			Array.prototype.forEach.call(iframes, (iframe) => {
				iframe.contentWindow.postMessage(JSON.stringify({ event: "command", func: "stopVideo" }), "*");
			});

			// mp4
			var videos = document.querySelectorAll('iframe, video');
			Array.prototype.forEach.call(videos, (video) => {
				if (video.tagName.toLowerCase() === 'video') {
					video.pause();
					video.currentTime = 0;
				} else {
					var src = video.src;
					video.src = src;
				}
			});
		},
	},
};
</script>
