<script>

export default {
	data() {
		return {
			stateList: [
				{
					state: "ACT",
					name: "Australian Capital Territory",
					bdmList: [
						{
							firstName: "Gabriel",
							lastName: "Fonseca",
							territory:
								"Sydney CBD, South-Western Sydney and ACT",
							funFact:
								"I Speak 3 languages, and worked in television for 10yrs before working in airlines",
							email: "Gabriel.Fonseca@airnz.co.nz",
							phoneNumber: "+61 0410 138 264",
							image: "Gabriel.F.jpeg",
							bookings: "mailto:Gabriel.Fonseca@airnz.co.nz",
							videoIntroduction: '/video/bdm/Gabriel+AIRNZ-32681-F-1+%E2%80%A2+Sydney+BDM+Introduction+Videos+%E2%80%A2+Approval+Candidate+%E2%80%A2+NA+%E2%80%A2+240129.mov',
						},
						{
							firstName: "Sharee",
							lastName: "Stolarski",
							// territory: "Sydney CBD, South-Western Sydney and ACT",
							// funFact: "Take a moment to appreciate the beauty.",
							email: "sharee.stolarski@airnz.co.nz",
							phoneNumber: "+61 414 747 270",
							hideButton: true,
							notes: "On parental leave until 2024, please get in touch with member of the ACT Sales Team",
							image: "Sharee.T.jpg",
							bookings: "mailto:sharee.stolarski@airnz.co.nz"
						},
					],
				},
				{
					state: "NSW",
					name: "New South Wales",
					bdmList: [
						{
							firstName: "Stella",
							lastName: "Hritis",
							territory:
								"Sutherland Shire, Inner West, East Sydney, North Shore, Northern Beaches, Central Coast, Newcastle, NSW North Coast, New England region",
							funFact:
								"I have travelled to 80 countries all around the world! I also have my scuba diving licence and always try and get a dive in whenever I go away on holiday.",
							email: "stella.hritis@airnz.co.nz ",
							phoneNumber: "+61 438 816 782",
							image: "Stella.H.jpg",
							bookings:
								"mailto:stella.hritis@airnz.co.nz",
							videoIntroduction: "/video/bdm/Stella+AIRNZ-32681-E-1+%E2%80%A2+Sydney+BDM+Introduction+Videos+%E2%80%A2+Deliverable+%E2%80%A2+NA+%E2%80%A2+240201.mov",
							videoIntroductionIndex: 3
						},
						{
							firstName: "Gabriel",
							lastName: "Fonseca",
							territory:
								"Sydney CBD, Western Sydney, Blue Mountains, NSW South Coast, Riverina, Central West NSW, ACT",
							funFact:
								"I Speak 3 languages, and worked in television for 10yrs before working in airlines",
							email: "Gabriel.Fonseca@airnz.co.nz",
							phoneNumber: "+61 0410 138 264",
							image: "Gabriel.F.jpeg",
							bookings: "mailto:Gabriel.Fonseca@airnz.co.nz",
							videoIntroduction: '/video/bdm/Gabriel+AIRNZ-32681-F-1+%E2%80%A2+Sydney+BDM+Introduction+Videos+%E2%80%A2+Approval+Candidate+%E2%80%A2+NA+%E2%80%A2+240129.mov',
							videoIntroductionIndex: 2
						},
						{
							firstName: "Sharee",
							lastName: "Stolarski",
							hideButton: true,
							notes: "On parental leave until 2024, please get in touch with member of the NSW Sales Team",
							// territory:
							//   "Sydney CBD, Western Sydney, Blue Mountains, NSW South Coast, Riverina, Central West NSW, ACT",
							// funFact:
							//   "I have a master's degree in Aviation from UNSW and have met Drew Barrymore and The Rock while working at London Heathrow Airport.",
							email: "sharee.stolarski@airnz.co.nz",
							phoneNumber: "+61 414 747 270",
							image: "Sharee.T.jpg",
							bookings:
								"mailto:sharee.stolarski@airnz.co.nz",
						},
					],
				},
				{
					state: "QLD",
					name: "Queensland",
					bdmList: [
						{
							firstName: "Jess",
							lastName: "Walker",
							territory:
								"Brisbane CBD, Brisbane River Southwest, Toowoomba, Sunshine Coast and Brisbane River North to Hervey Bay",
							funFact:
								"Jess is a big fan of country music!",
							email: "jess.walker@airnz.co.nz",
							phoneNumber: "+61 406 632 071",
							image: "JessWalker.jpg",
							bookings:
								"mailto:jess.walker@airnz.co.nz",
						},
					],
				},
				{
					state: "SA&TAS",
					name: "South Australia and Tasmania",
					bdmList: [
						{
							firstName: "Karen",
							lastName: "Koval",
							territory: "South Australia and Tasmania",
							funFact:
								"Travelled to Hawaii to meet up with my American fiance for a short getaway only to spend most of my time there applying for a new passport, tickets etc after having my travel wallet stolen.",
							notes: "Working days are Mon, Tue & Thu.",
							email: "karen.koval@airnz.co.nz",
							phoneNumber: "+61 414747238",
							image: "Karen.K.jpg",
							bookings:
								"mailto:karen.koval@airnz.co.nz",
						},
					],
				},
				{
					state: "VIC",
					name: "Victoria",
					bdmList: [
						{
							firstName: "Mel",
							lastName: "van Twest",
							territory: "North East, East and South East VIC",
							funFact:
								"In 2015 I went cage diving in South Africa and came up close and personal with Great White Sharks.",
							email: "mel.vantwest@airnz.co.nz",
							phoneNumber: "+61 409 076 522",
							image: "Mellisa.V.T.jpg",
							bookings:
								"mailto:mel.vantwest@airnz.co.nz",
						},
						{
							firstName: "Melissa",
							lastName: "Train",
							territory: "North West, West and South West VIC",
							funFact:
								"I lived in Seattle as part of my gap year at university and think I was a yank in my past life. Whenever I land in the USA, I feel right at home.",
							email: "melissa.train@airnz.co.nz",
							phoneNumber: "+61 414 691 812",
							image: "Mellisa.T.jpg",
							bookings:
								"mailto:melissa.train@airnz.co.nz",
						},
						{
							firstName: "Thalia",
							lastName: "Ashley",
							territory: "Melbourne, Direct Corporate Customers and TMC's",
							funFact:
								": I love live music and concerts and wish I could sing!",
							email: "thalia.ashley@airnz.co.nz ",
							phoneNumber: "+61 414 697 443",
							image: "Thalia.A.jpg",
							bookings:
								"mailto:thalia.ashley@airnz.co.nz",
						},
					],
				},
				{
					state: "WA&NT",
					name: "Western Australia and Northern Territory",
					bdmList: [
						{
							firstName: "Janelle",
							lastName: "Philpott",
							territory: "Western Australia and Northern Territory",
							funFact:
								"I was lucky enough to travel to Graceland and visit the birthplace of Elvis.",
							email: "janelle.philpott@airnz.co.nz",
							phoneNumber: "+61 438 816 573",
							image: "Janelle.P.jpg",
							bookings:
								"mailto:janelle.philpott@airnz.co.nz",
						},
					],
				},
			],
		};
	},
};
</script>
